import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BreadCrumb from "../components/BreadCrumb";
import ProductGrid from "../components/ProductGrid";
import { RiCustomerService2Line, RiServiceLine } from "react-icons/all";
import QuotesItem from "../components/QuotesItem";
import SEO from "../components/Seo";
const ServicePage = (props) => {
  const { data } = props;
  return (
    <Layout details={data}>
        <SEO
        pathname="/"
        title={`Nastesco Marine Pte Ltd | Services`}
        keywords={["Nastesco Services"]}
        />
      <BreadCrumb
        list={[
          {
            title: "Services",
            href: "/services",
            active: true,
          },
        ]}
      />
      <div className="bg-[#f7f7f7] bg-gradient-to-t from-gray-100  relative z-[1] service-section">
        <div className=" mx-auto px-16 py-24 max-w-7xl xs:px-3 flex gap-5 xs:flex-wrap">
          <div className="bg-slate-50 rounded-lg p-4 shadow-md w-1/2 xs:w-full">
            <p className="flex flex-nowrap items-center text-lg font-semibold border-b-2 pb-4">
              <div className="service-icon-round">
                <RiCustomerService2Line size={36} />
              </div>
              &nbsp; Key Services
            </p>
            <div className="flex flex-col gap-3 py-5">
              {data?.services?.nodes?.[0]?.key_services &&
                data?.services?.nodes?.[0]?.key_services.map(
                  (service, index) => {
                    return (
                      <QuotesItem
                        className="bg-white shadow-sm hover:shadow-lg hover:scale-105 transition-all duration-300"
                        text={service}
                        key={index}
                      />
                    );
                  }
                )}
            </div>
          </div>
          <div className="bg-slate-50 rounded-lg p-4 shadow-md w-1/2 xs:w-full">
            <p className="flex flex-nowrap items-center text-lg font-semibold border-b-2 pb-4">
              <div className="service-icon-round">
                <RiServiceLine size={36} />
              </div>
              &nbsp; Value Added Services
            </p>
            <div className="flex flex-col gap-3 py-5">
              {data?.services?.nodes?.[0]?.value_added_services &&
                data?.services?.nodes?.[0]?.value_added_services.map(
                  (service, index) => {
                    return (
                      <QuotesItem
                        className="bg-white shadow-sm hover:shadow-lg hover:scale-105 transition-all duration-300"
                        text={service}
                        key={index}
                      />
                    );
                  }
                )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    settings: allSanitySettings {
      nodes {
        id
        address_details {
          address_text
          city
          country
          office_location {
            latitude
            longitude
          }
          site_email
          site_phone
          state
        }
        business_hours {
          closed
          open
        }
        copyright_year
        shipserve_details {
          logo {
            asset {
              url
            }
          }
          trade_id
        }
        site_logo {
          asset {
            url
          }
        }
        site_name
        social_links {
          facebook_link
          linkedin_link
          tweeter_link
        }
      }
    }
    company_profile: allSanityCompanyProfile {
      nodes {
        about_us
        full_description {
          description
          description_image {
            asset {
              url
            }
          }
        }
        policy_details {
          description
          policy_image {
            asset {
              url
            }
          }
        }
      }
    }
    services: allSanityServices {
      totalCount
      nodes {
        _id
        value_added_services
        key_services
      }
    }
  }
`;
export default ServicePage;
