import React from "react";

const QuotesItem = ({ text, ...props }) => {
  return (
    <div
      key={props.key}
      className={"logicnibble-quote-panel " + props.className}
    >
      <p>{text}</p>
    </div>
  );
};

export default QuotesItem;
