import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import voca from "voca";
import slug from "slug";
const ProductGrid = (props) => {
  const { products, product_categories } = props;
  const addOrRemoveArray = (arr, item) =>
    typeof arr === Array
      ? arr.includes(item)
        ? arr.filter((i) => i !== item)
        : [...arr, item]
      : [item];
  const itemsPerPage = 6;
  const [pageLimit, setPageLimit] = useState(itemsPerPage);
  const [selectedCategory, setSelectedCategory] = useState(null);

  let FilteredList = !selectedCategory
    ? products
    : products.filter((obj) => {
        return selectedCategory === slug(obj?.product_category?.title);
      });
  return (
    <div className="bg-[#f7f7f7] bg-gradient-to-t from-gray-100  relative z-[1] product-section overflow-hidden">
      <div className=" mx-auto px-16 py-24 max-w-7xl xs:px-3 pt-5">
        <div className="bg-white mb-8 py-3 pt-4 px-3 shadow-lg rounded-md grid">
          <div className="text-md text-blue-700 px-1 py-1 pb-2 border-b mb-4">
            Product Categories
          </div>
          <div className="flex flex-wrap gap-1 items-center ">
            {product_categories &&
              product_categories.map((category, index) => {
                if (slug(category.title) === selectedCategory) {
                  return (
                      <button
                      key={index}
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 rounded-lg py-2 px-3 text-xs font-medium text-center mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      value={slug(category?.title)}
                      onClick={(e) => {
                        setSelectedCategory(slug(e.target.value));
                      }}
                    >
                      {category?.title}
                    </button>
                  );
                } else {
                  return (
                    <button
                    key={index}
                      type="button"
                      className="py-2 px-3 text-xs shadow-sm  mr-2 mb-2  font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      value={slug(category?.title)}
                      onClick={(e) => {
                        setSelectedCategory(slug(e.target.value));
                      }}
                    >
                      {category?.title}
                    </button>
                  );
                }
              })}
            {selectedCategory && (
              <button
                key="clear_zhfhjsdgfhdgf"
                onClick={(e) => {
                  setSelectedCategory(slug(e.target.value));
                  setPageLimit(itemsPerPage);
                }}
                type="button"
                class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-lg py-2 px-3 text-xs text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
              >
                Clear
              </button>
            )}
          </div>
        </div>
        <div
          className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 sm:gap-y-16 md:grid-cols-3 [&:not(:focus-visible)]:focus:outline-none"
          id="headlessui-tabs-panel-:Rql9m:"
          role="tabpanel"
          tabIndex="0"
          aria-labelledby="headlessui-tabs-tab-:R1j6l9m:"
        >
          {FilteredList &&
            FilteredList.slice(0, pageLimit).map(
              (product, index) => {
                const productSlug = `product/${slug(
                  voca.lowerCase(product?.title)
                )}-${product?._id}`;
                return (
                  <Link to={`/` + productSlug} key={index}>
                    <div className="bg-white rounded-lg overflow-hidden  shadow-md hover:shadow-xl">
                      <div className="group relative h-[17.5rem] transform overflow-hidden rounded-4xl">
                        <div className="absolute top-0 left-0 right-4 bottom-6 rounded-4xl border transition duration-300 group-hover:scale-95 xl:right-6 border-blue-300"></div>
                        <div className="absolute inset-0 bg-indigo-50">
                          <img
                            alt=""
                            src={product?.image?.asset?.url}
                            className="absolute inset-0 h-full w-full object-cover transition duration-300 group-hover:scale-110"
                          />
                        </div>
                      </div>
                      <div className="p-[15px]">
                        {/* <span class="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"></span> */}
                        <span class="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                          {product?.product_category?.title}
                        </span>
                        <h3 className="mt-4 font-display text-xl font-bold tracking-tight text-slate-700">
                          {product?.title}
                        </h3>

                        <p className="mt-1 text-base tracking-tight text-slate-500">
                          {product?.sort_description?.substring(0, 80) + "..."}
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              }
            )}
        </div>
        { FilteredList.length > pageLimit ? (
          <div className="flex items-center justify-end pt-5 xs:justify-center sm:justify-center">
            <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={() => setPageLimit(pageLimit + itemsPerPage)}
            >
              Load More..
              <svg
                aria-hidden="true"
                className="ml-2 -mr-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductGrid;
